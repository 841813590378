import {
  getFormatList,
  getUsageList,
  getCategoryList,
  getCountryList,
  getWineColourList,
  getDutyStatus,
  getContactStatusList,
  getIsoCountryList,
  getPaymentTermsList,
  getPromotionProfileList,
  getUnusedPromotionProfileCodes,
  getWorkflowStatus,
  getIsoInstastatCountryList,
  getDeliveryInstructions,
  getOAuthClients,
  getDesignations,
  getClassifications,
  getCountryRegions,
  getCountrySubRegions,
  getVineyards,
  getBillingRunPeriods,
  getStoragePricingGroups
} from '@/api/lists'
import { getUserOptions } from '../api/users'
import { getShipperAddresses } from '../api/administration/shipperaddresses'

import { find } from 'lodash'

export const state = () => ({
  formatOptions: [],
  usageOptions: [],
  categoryOptions: [],
  countryOptions: [],
  instastatCountryOptions: [],
  wineColourOptions: [],
  dutyStatusOptions: [],
  contactStatusOptions: [],
  isoCountryDropDownOptions: [],
  paymentTermsDropDownOptions: [],
  promotionProfileOptions: [],
  unusedPromotionProfileOptions: [],
  workflowStatusOptions: [],
  deliveryInstructionOptions: [],
  designationOptions: [],
  classificationOptions: [],
  countrySubRegionOptions: [],
  countryRegionOptions: [],
  vineyardOptions: [],
  oAuthClients: [],
  userOptions: [],
  shipperAddressOptions: [],
  billingRunPeriodOptions: [],
  pricingGroupOptions: [],
  loadingFormatOptions: false,
  loadingUsageOptions: false,
  loadingCategoryOptions: false,
  loadingCountryOptions: false,
  loadingInstastatCountryOptions: false,
  loadingWineColourOptions: false,
  loadingDutyStatusOptions: false,
  loadingContactStatusOptions: false,
  loadingIsoCountryDropDownOptions: false,
  loadingPaymentTermsDropDownOptions: false,
  loadingPromotionProfileOptions: false,
  loadingUnusedPromotionProfileOptions: false,
  loadingWorkflowStatusOptions: false,
  loadingDeliveryInstructions: false,
  loadingDesignations: false,
  loadingClassifications: false,
  loadingCountryRegions: false,
  loadingCountrySubRegions: false,
  loadingVineyards: false,
  loadingUsers: false,
  loadingPricingGroupOptions: false,
  loadedFormatOptions: false,
  loadedUsageOptions: false,
  loadedCategoryOptions: false,
  loadedCountryOptions: false,
  loadingShipperAddressOptions: false,
  loadingBillingRunPeriodOptions: false,
  loadedInstastatCountryOptions: false,
  loadedWineColourOptions: false,
  loadedDutyStatusOptions: false,
  loadedContactStatusOptions: false,
  loadedIsoCountryDropDownOptions: false,
  loadedPaymentTermsDropDownOptions: false,
  loadedPromotionProfileOptions: false,
  loadedUnusedPromotionProfileOptions: false,
  loadedWorkflowStatusOptions: false,
  loadedDeliveryInstructions: false,
  loadingOAuthClients: false,
  loadedOAuthClients: false,
  loadedDesignations: false,
  loadedClassifications: false,
  loadedCountryRegions: false,
  loadedCountrySubRegions: false,
  loadedVineyards: false,
  loadedUsers: false,
  loadedShipperAddressOptions: false,
  loadedBillingRunPeriodOptions: false,
  loadedPricingGroupOptions: false,
  salesOrderPaymentStatusOptions: [
    {
      text: 'Paid',
      value: 1
    },
    {
      text: 'Not Paid',
      value: 2
    },
    {
      text: 'Terms',
      value: 3
    },
    {
      text: 'Part Paid',
      value: 4
    },
    {
      text: 'Credited',
      value: 5
    }
  ],
  salesOrderShippingOptions: [
    {
      text: 'Shipped',
      value: 1
    },
    {
      text: 'Not Shipped',
      value: 2
    },
    {
      text: 'Part Shipped',
      value: 3
    },
    {
      text: 'Credited',
      value: 4
    }
  ],
  shippingInstructionOptions: [
    {
      value: 1,
      text: 'LCB'
    },
    {
      value: 2,
      text: 'WV'
    },
    {
      value: 3,
      text: 'Other'
    },
    {
      value: 4,
      text: 'Internal Warehouse Move'
    },
    {
      value: 5,
      text: 'Customer Collection'
    },
    {
      value: 6,
      text: 'Do Not Deliver'
    },
    {
      value: 7,
      text: 'Reserves'
    }
  ]
})

export const getters = {
  getFullFormatList: state => {
    return state.formatOptions
  },
  getFullcategoryList: state => {
    return state.categoryOptions
  },
  getCategoryByValue: state => value => {
    return find(state.categoryOptions, { value })
  },
  getFormatByValue: state => value => {
    return find(state.formatOptions, { value })
  },
  getUsageByValue: state => value => {
    return find(state.usageOptions, { value })
  },
  getDutyStatusByValue: state => value => {
    return find(state.dutyStatusOptions, { value })
  },
  getFormatByCode: state => code => {
    return find(state.formatOptions, { code })
  },
  getUsageByText: state => text => {
    return find(state.usageOptions, { text })
  },
  getDeliveryInstructionById: state => value => {
    return find(state.deliveryInstructionOptions, { value })
  },
  getUserList: state => {
    return state.userOptions.filter(u => !u.Disabled).map(u => {
      return {
        value: u.UserID,
        text: u.UserName
      }
    })
  },
  formatsWithLargeForms: state => {
    let formatOptionsCloned = state.formatOptions.slice(0)
    formatOptionsCloned.push({
      value: 9999,
      text: 'LARGE Formats',
      caseSize: 0,
      code: '',
      volume: 751
    })
    formatOptionsCloned = formatOptionsCloned.sort((a, b) => {
      if (a.code === 'B') {
        return -1
      }
      if (b.code === 'B') {
        return 1
      }
      return a.volume - b.volume
    })
    formatOptionsCloned.unshift({
      value: null,
      text: 'All',
      code: '',
      volume: -1,
      selected: true
    })
    return formatOptionsCloned
  }
}

export const mutations = {
  setFormatList(state, formatOptions) {
    state.formatOptions = formatOptions
    state.loadedFormatOptions = true
  },
  setUsageList(state, usageOptions) {
    state.usageOptions = usageOptions
    state.loadedUsageOptions = true
  },
  setCategoryList(state, categoryOptions) {
    state.categoryOptions = categoryOptions
    state.loadedCategoryOptions = true
  },
  setCountryList(state, countryOptions) {
    state.countryOptions = countryOptions
    state.loadedCountryOptions = true
  },
  setInstastatCountryList(state, countryOptions) {
    state.instastatCountryOptions = countryOptions
    state.loadedInstastatCountryOptions = true
  },
  setDutyStatusList(state, dutyOptions) {
    state.dutyStatusOptions = dutyOptions
    state.loadedDutyStatusOptions = true
  },
  setWineColourList(state, wineColourOptions) {
    state.wineColourOptions = wineColourOptions
    state.loadedWineColourOptions = true
  },
  setContactStatusList(state, contactStatusOptions) {
    state.contactStatusOptions = contactStatusOptions
  },
  setIsoCountryDropDownList(state, isoCountryDropDownOptions) {
    state.isoCountryDropDownOptions = isoCountryDropDownOptions
  },
  setPaymentTermsDropDownList(state, paymentTermsDropDownOptions) {
    state.paymentTermsDropDownOptions = paymentTermsDropDownOptions
  },
  setPromotionProfileList(state, promotionProfileOptions) {
    state.promotionProfileOptions = promotionProfileOptions
  },
  setPricingGroupOptionsList(state, pricingGroupOptions) {
    state.pricingGroupOptions = pricingGroupOptions
  },
  setUnusedPromotionProfileList(state, unusedPromotionProfileOptions) {
    state.unusedPromotionProfileOptions = unusedPromotionProfileOptions
  },
  setWorkflowStatusOptions(state, workflowStatusOptions) {
    state.workflowStatusOptions = workflowStatusOptions
  },
  setDeliveryInstructionOptions(state, deliveryInstructionOptions) {
    state.deliveryInstructionOptions = deliveryInstructionOptions
  },
  setDesignations(state, designations) {
    state.designationOptions = designations
  },
  setClassifications(state, classifications) {
    state.classificationOptions = classifications
  },
  setCountryRegions(state, regions) {
    state.countryRegionOptions = regions
  },
  setCountrySubRegions(state, subRegions) {
    state.countrySubRegionOptions = subRegions
  },
  setVineyards(state, vineyards) {
    state.vineyardOptions = vineyards
  },
  setOAuthClients(state, oAuthClients) {
    state.oAuthClients = oAuthClients
  },
  setShipperAddressOptions(state, addressess) {
    state.shipperAddressOptions = addressess
  },
  setBillingRunPeriodOptions(state, billingRunPeriods) {
    state.billingRunPeriodOptions = billingRunPeriods
  },
  setLoadingFormat(state, loading) {
    state.loadingFormatOptions = loading
    state.loadedFormatOptions = !loading
  },
  setLoadingUsage(state, loading) {
    state.loadingUsageOptions = loading
    state.loadedUsageOptions = !loading
  },
  setLoadingCategory(state, loading) {
    state.loadingCategoryOptions = loading
    state.loadedCategoryOptions = !loading
  },
  setLoadingCountry(state, loading) {
    state.loadingCountryOptions = loading
    state.loadedCountryOptions = !loading
  },
  setLoadingInstastatCountry(state, loading) {
    state.loadingInstastatCountryOptions = loading
    state.loadedInstastatCountryOptions = !loading
  },
  setLoadingWineColour(state, loading) {
    state.loadingWineColourOptions = loading
    state.loadedWineColourOptions = !loading
  },
  setLoadingContactStatus(state, loading) {
    state.loadingContactStatusOptions = loading
    state.loadedContactStatusOptions = !loading
  },
  setLoadingIsoCountryDropDown(state, loading) {
    state.loadingIsoCountryDropDownOptions = loading
    state.loadedIsoCountryDropDownOptions = !loading
  },
  setLoadingPaymentTermsDropDown(state, loading) {
    state.loadingPaymentTermsDropDownOptions = loading
    state.loadedPaymentTermsDropDownOptions = !loading
  },
  setLoadingPromotionProfileOptions(state, loading) {
    state.loadingPromotionProfileOptions = loading
    state.loadedPromotionProfileOptions = !loading
  },
  setLoadingUnusedPromotionProfileOptions(state, loading) {
    state.loadingUnusedPromotionProfileOptions = loading
    state.loadedUnusedPromotionProfileOptions = !loading
  },
  setLoadingPricingGroupOptions(state, loading) {
    state.loadingPricingGroupOptions = loading
    state.loadedPricingGroupOptions = !loading
  },
  setNewFormat(state, newFormat) {
    state.formatOptions.push(newFormat)
  },
  setLoadingDutyStatus(state, loading) {
    state.loadingDutyStatusOptions = loading
    state.loadedDutyStatusOptions = !loading
  },
  setLoadingWorkflowStatusOptions(state, loading) {
    state.loadingWorkflowStatusOptions = loading
    state.loadedWorkflowStatusOptions = !loading
  },
  setLoadingDeliveryInstructions(state, loading) {
    state.loadingDeliveryInstructions = loading
    state.loadedDeliveryInstructions = !loading
  },
  setLoadingOAuthClients(state, loading) {
    state.loadingOAuthClients = loading
    state.loadedOAuthClients = !loading
  },
  setLoadingDesignations(state, loading) {
    state.loadingDesignations = loading
    state.loadedDesignations = !loading
  },
  setLoadingClassifications(state, loading) {
    state.loadingClassifications = loading
    state.loadedClassifications = !loading
  },
  setLoadingCountryRegions(state, loading) {
    state.loadingCountryRegions = loading
    state.loadedCountryRegions = !loading
  },
  setLoadingCountrySubRegions(state, loading) {
    state.loadingCountrySubRegions = loading
    state.loadedCountrySubRegions = !loading
  },
  setLoadingVineyards(state, loading) {
    state.loadingVineyards = loading
    state.loadedVineyards = !loading
  },
  setLoadingUsers(state, loading) {
    state.loadingUsers = loading
    state.loadedUsers = !loading
  },
  setLoadingShipperAddresses(state, loading) {
    state.loadingShipperAddressOptions = loading
    state.loadedShipperAddressOptions = !loading
  },
  setLoadingBillingRunPeriods(state, loading) {
    state.loadingBillingRunPeriodOptions = loading
    state.loadedBillingRunPeriodOptions = !loading
  },
  setUserOptions(state, users) {
    state.userOptions = users
  },
  appendCountry(state, country) {
    const currentCountry = find(state.countryOptions, {
      CountryID: country.CountryID
    })
    if (!currentCountry) {
      state.countryOptions.push(country)
    }
  },
  appendCountryRegion(state, region) {
    const current = find(state.countryRegionOptions, {
      CountryRegionID: region.CountryRegionID
    })
    if (!current) {
      state.countryRegionOptions.push(region)
    }
  },
  appendCountrySubRegion(state, region) {
    const current = find(state.countrySubRegionOptions, {
      CountrySubRegionID: region.CountrySubRegionID
    })
    if (!current) {
      state.countrySubRegionOptions.push(region)
    }
  },
  appendDesignation(state, designation) {
    const current = find(state.designationOptions, {
      DesignationID: designation.DesignationID
    })
    if (!current) {
      state.designationOptions.push(designation)
    }
  },
  appendClassification(state, classification) {
    const current = find(state.classificationOptions, {
      ClassificationID: classification.ClassificationID
    })
    if (!current) {
      state.classificationOptions.push(classification)
    }
  },
  appendVineyard(state, vineyard) {
    const current = find(state.vineyardOptions, {
      VineyardID: vineyard.VineyardID
    })
    if (!current) {
      state.vineyardOptions.push(vineyard)
    }
  },
  appendWineColour(state, colour) {
    const current = find(state.wineColourOptions, {
      WineColourID: colour.WineColourID
    })
    if (!current) {
      state.wineColourOptions.push(colour)
    }
  }
}

export const actions = {
  refreshFormat({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingFormatOptions === false &&
          (state.loadedFormatOptions === false || force)
        ) {
          commit('setLoadingFormat', true)
          const response = await getFormatList(searchTerm)
          commit('setFormatList', response)
          commit('setLoadingFormat', false)
        }
        resolve(state.formatOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshUsage({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingUsageOptions === false &&
          (state.loadedUsageOptions === false || force)
        ) {
          commit('setLoadingUsage', true)
          const response = await getUsageList(searchTerm)
          commit('setUsageList', response)
          commit('setLoadingUsage', false)
        }
        resolve(state.usageOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshInstastatCountry({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingInstastatCountryOptions === false &&
          (state.loadedInstastatCountryOptions === false || force)
        ) {
          commit('setLoadingInstastatCountry', true)
          const response = await getIsoInstastatCountryList(searchTerm)
          commit('setInstastatCountryList', response)
          commit('setLoadingInstastatCountry', false)
        }
        resolve(state.instastatCountryOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshCountry({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingCountryOptions === false &&
          (state.loadedCountryOptions === false || force)
        ) {
          commit('setLoadingCountry', true)
          const response = await getCountryList(searchTerm)
          commit('setCountryList', response)
          commit('setLoadingCountry', false)
        }
        resolve(state.countryOptions)
      } catch (e) {
        reject(e)
      }
    })
  },

  refreshWineColour({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingWineColourOptions === false &&
          (state.loadedWineColourOptions === false || force)
        ) {
          commit('setLoadingWineColour', true)
          const response = await getWineColourList(searchTerm)
          commit('setWineColourList', response)
          commit('setLoadingWineColour', false)
        }
        resolve(state.wineColourOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshCategory({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingCategoryOptions === false &&
          (state.loadedCategoryOptions === false || force)
        ) {
          commit('setLoadingCategory', true)
          const response = await getCategoryList(searchTerm)
          commit('setCategoryList', response)
          commit('setLoadingCategory', false)
        }
        resolve(state.categoryOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshDutyStatus({ commit, state }, { force, searchTerm }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingDutyStatusOptions === false &&
          (state.loadedDutyStatusOptions === false || force)
        ) {
          commit('setLoadingDutyStatus', true)
          const response = await getDutyStatus(searchTerm)
          commit('setDutyStatusList', response)
          commit('setLoadingDutyStatus', false)
        }
        resolve(state.categoryOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshContactStatus({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingContactStatusOptions === false &&
          (state.loadedContactStatusOptions === false || force)
        ) {
          commit('setLoadingContactStatus', true)
          const response = await getContactStatusList()
          commit('setContactStatusList', response)
          commit('setLoadingContactStatus', false)
        }
        resolve(state.contactStatusOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshIsoCountry({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingIsoCountryDropDownOptions === false &&
          (state.loadedIsoCountryDropDownOptions === false || force)
        ) {
          commit('setLoadingIsoCountryDropDown', true)
          const response = await getIsoCountryList()
          commit('setIsoCountryDropDownList', response)
          commit('setLoadingIsoCountryDropDown', false)
        }
        resolve(state.isoCountryDropDownOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshDesignations({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingDesignations === false &&
          (state.loadedDesignations === false || force)
        ) {
          commit('setLoadingDesignations', true)
          const response = await getDesignations()
          commit('setDesignations', response)
          commit('setLoadingDesignations', false)
        }
        resolve(state.designationOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshClassifications({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingClassifications === false &&
          (state.loadedClassifications === false || force)
        ) {
          commit('setLoadingClassifications', true)
          const response = await getClassifications()
          commit('setClassifications', response)
          commit('setLoadingClassifications', false)
        }
        resolve(state.classificationOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshCountryRegions({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingCountryRegions === false &&
          (state.loadedCountryRegions === false || force)
        ) {
          commit('setLoadingCountryRegions', true)
          const response = await getCountryRegions()
          commit('setCountryRegions', response)
          commit('setLoadingCountryRegions', false)
        }
        resolve(state.countryRegionOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshCountrySubRegions({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingCountrySubRegions === false &&
          (state.loadedCountrySubRegions === false || force)
        ) {
          commit('setLoadingCountrySubRegions', true)
          const response = await getCountrySubRegions()
          commit('setCountrySubRegions', response)
          commit('setLoadingCountrySubRegions', false)
        }
        resolve(state.countrySubRegionOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshVineyards({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingVineyards === false &&
          (state.loadedVineyards === false || force)
        ) {
          commit('setLoadingVineyards', true)
          const response = await getVineyards()
          commit('setVineyards', response)
          commit('setLoadingVineyards', false)
        }
        resolve(state.countrySubRegionOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshPaymentTerms({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingPaymentTermsDropDownOptions === false &&
          (state.loadedPaymentTermsDropDownOptions === false || force)
        ) {
          commit('setLoadingPaymentTermsDropDown', true)
          const response = await getPaymentTermsList()
          commit('setPaymentTermsDropDownList', response)
          commit('setLoadingPaymentTermsDropDown', false)
        }
        resolve(state.paymentTermsDropDownOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshPromotionProfile({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingPromotionProfileOptions === false &&
          (state.loadedPromotionProfileOptions === false || force)
        ) {
          commit('setLoadingPromotionProfileOptions', true)
          const response = await getPromotionProfileList()
          commit('setPromotionProfileList', response)
          commit('setLoadingPromotionProfileOptions', false)
        }
        resolve(state.promotionProfileOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshUnusedPromotionProfiles({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingUnusedPromotionProfileOptions === false &&
          (state.loadedUnusedPromotionProfileOptions === false || force)
        ) {
          commit('setLoadingUnusedPromotionProfileOptions', true)
          const response = await getUnusedPromotionProfileCodes()
          commit('setUnusedPromotionProfileList', response)
          commit('setLoadingUnusedPromotionProfileOptions', false)
        }
        resolve(state.unusedPromotionProfileOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshPricingGroupOptions({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingPricingGroupOptions === false &&
          (state.loadedPricingGroupOptions === false || force)
        ) {
          commit('setLoadingPricingGroupOptions', true)
          const response = await getStoragePricingGroups()
          commit('setPricingGroupOptionsList', response)
          commit('setLoadingPricingGroupOptions', false)
        }
        resolve(state.pricingGroupOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshWorkflowStatus({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingWorkflowStatusOptions === false &&
          (state.loadedWorkflowStatusOptions === false || force)
        ) {
          commit('setLoadingWorkflowStatusOptions', true)
          const response = await getWorkflowStatus()
          commit('setWorkflowStatusOptions', response)
          commit('setLoadingWorkflowStatusOptions', false)
        }
        resolve(state.workflowStatusOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshDeliveryInstructions({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingDeliveryInstructions === false &&
          (state.loadedDeliveryInstructions === false || force)
        ) {
          commit('setLoadingDeliveryInstructions', true)
          const response = await getDeliveryInstructions()
          commit('setDeliveryInstructionOptions', response)
          commit('setLoadingDeliveryInstructions', false)
        }
        resolve(state.deliveryInstructionOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshOAuthClients({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingOAuthClients === false &&
          (state.loadedOAuthClients === false || force)
        ) {
          commit('setLoadingOAuthClients', true)
          const response = await getOAuthClients()
          commit('setOAuthClients', response)
          commit('setLoadingOAuthClients', false)
        }
        resolve(state.oAuthClients)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshUsers({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingUsers === false &&
          (state.loadedUsers === false || force)
        ) {
          commit('setLoadingUsers', true)
          const response = await getUserOptions()
          commit('setUserOptions', response)
          commit('setLoadingUsers', false)
        }
        resolve(state.oAuthClients)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshShipperAddresses({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingShipperAddressOptions === false &&
          (state.loadedShipperAddressOptions === false || force)
        ) {
          commit('setLoadingShipperAddresses', true)
          const response = await getShipperAddresses()
          commit('setShipperAddressOptions', response)
          commit('setLoadingShipperAddresses', false)
        }
        resolve(state.shipperAddressOptions)
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshBillingRunPeriods({ commit, state }, { force }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          state.loadingBillingRunPeriodOptions === false &&
          (state.loadedBillingRunPeriodOptions === false || force)
        ) {
          commit('setLoadingBillingRunPeriods', true)
          const response = await getBillingRunPeriods()
          commit('setBillingRunPeriodOptions', response)
          commit('setLoadingBillingRunPeriods', false)
        }
        resolve(state.billingRunPeriodOptions)
      } catch (e) {
        reject(e)
      }
    })
  }
}
