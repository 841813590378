<template>
  <group-box class="h-100" body-class="p-2">
    <template slot="header">
      Recipient
    </template>
    <b-form-group
      label-size="xs"
      label-cols="4"
      class="m-0"
    >
      <template slot="label">
        <b-radio v-model="emailRecipient" value="toSelf">
          To Self (Bulk)
        </b-radio>
      </template>
    </b-form-group>
    <b-form-group
      label-size="xs"
      label-cols="4"
      class="m-0"
    >
      <template slot="label">
        <b-radio v-model="emailRecipient" value="toCustomer" :disabled="contact && contact.NoEmailContact">
          To Customer
        </b-radio>
      </template>
      <b-input-group size="sm">
        <b-form-input
          id="ccdf_search"
          v-model="SearchTerm"
          :state="!$v.SearchTerm.$error && null"
          @keydown.enter="handleContactSearch"
        />
        <b-input-group-append v-if="contactCanChange">
          <b-button size="sm" @click="handleContactSearch">
            Search
          </b-button>
        </b-input-group-append>
        <b-form-invalid-feedback>
          Name Is Required
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label-size="xs"
      label-cols="4"
      label="Customer"
      class="m-0"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="customerName"
          :disabled="true"
        />
        <b-input-group-append v-show="contact" id="company-status-icon" style="cursor: default;">
          <i
            class="material-icons p-0 m-0 ml-1 mt-1 border-0"
            :style="{
              color: contact && contact.NoEmailContact ? '#e93223' : '#1f35f4'
            }"
          >
            {{ contact && contact.NoEmailContact ? 'cancel' : 'check_circle' }}
          </i>
        </b-input-group-append>
        <b-tooltip v-if="contact" target="company-status-icon" placement="right">
          <template v-if="contact.NoEmailContact">
            No Email Contact
          </template>
          <template v-else>
            Contact Allowed
          </template>
        </b-tooltip>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label-size="xs"
      label-cols="4"
      label="Email"
      class="m-0"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="EmailAddress"
          :state="!$v.EmailAddress.$error && null"
        />
        <b-form-invalid-feedback>
          Email Address is invalid
        </b-form-invalid-feedback>
        <b-input-group-append>
          <b-button id="emailPopover" variant="link" class="p-0 m-0 ml-1 mt-1 border-0">
            <i class="material-icons">
              info
            </i>
          </b-button>
          <email-popover />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
      label-size="xs"
      label-cols="4"
      label="Dear"
      class="m-0"
    >
      <b-input-group size="sm">
        <b-form-input v-model="Salutation" />
      </b-input-group>
    </b-form-group>
  </group-box>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '~/mixins/forms'
import { validateMultipleEmailsWithNames } from '~/helpers/validators'
import { required } from 'vuelidate/lib/validators'
import GroupBox from '~/components/Base/GroupBox'
import UserMixin from '~/mixins/user'
import EmailPopover from '~/components/Popovers/EmailPopover'

export default {
  components: { GroupBox, EmailPopover },
  mixins: [validationMixin, UserMixin, formObjectMixin],
  props: {
    contactCanChange: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    emailRecipient: {
      get() {
        return this.$store.state.export.email.options.ToSelf
          ? 'toSelf'
          : 'toCustomer'
      },
      set(value) {
        this.$emit('change-to-self', value === 'toSelf')
      }
    },
    customerName() {
      if (this.contact) {
        return this.contact.ContactName &&
          this.contact.ContactName.trim().length > 0
          ? this.contact.ContactName.trim()
          : this.contact.CompanyName
            ? this.contact.CompanyName.trim()
            : ''
      }
      return ''
    },
    toSelf() {
      return this.emailRecipient === 'toSelf'
    },
    SearchTerm: {
      get() {
        if (
          this.$store.state.export.email &&
          this.$store.state.export.email.options &&
          this.$store.state.export.email.options.ToName &&
          this.$store.state.export.email.options.ToName.length > 0
        ) {
          return this.$store.state.export.email.options.ToName
        }
        if (this.toSelf) {
          return this.$store.state.user.user.name
        }
        if (this.contact) {
          return this.contact.ContactName &&
            this.contact.ContactName.trim().length
            ? this.contact.ContactName.trim()
            : this.contact.CompanyName
              ? this.contact.CompanyName.trim()
              : ''
        }
        return ''
      },
      set(value) {
        this.$emit('change-to-name', value)
      }
    },
    EmailAddress: {
      get() {
        if (
          this.$store.state.export.email &&
          this.$store.state.export.email.options &&
          this.$store.state.export.email.options.To &&
          this.$store.state.export.email.options.To.length > 0
        ) {
          return this.$store.state.export.email.options.To
        }
        if (this.toSelf) {
          return this.$store.state.user.user.email
        }
        if (this.contact && this.contact.EmailAddress) {
          return this.contact.EmailAddress.trim()
        }
        if (this.contact && this.contact.EmailAddress1) {
          return this.contact.EmailAddress1.trim()
        }
        if (this.contact && this.contact.EmailAddress2) {
          return this.contact.EmailAddress2.trim()
        }
        if (this.contact && this.contact.EmailAddress3) {
          return this.contact.EmailAddress3.trim()
        }
        return ''
      },
      set(value) {
        this.$emit('change-to', value)
      }
    },
    Salutation: {
      get() {
        if (
          this.$store.state.export.email.options.Salutation !== null &&
          this.$store.state.export.email.options.Salutation !== undefined &&
          this.$store.state.export.email.options.Salutation.length > 0
        ) {
          return this.$store.state.export.email.options.Salutation
        }
        if (this.toSelf) {
          return this.$store.state.user.user.name
        }
        if (this.contact) {
          return this.contact.Salutation
        }
        return ''
      },
      set(value) {
        this.$emit('change-salutation', value)
      }
    }
  },
  watch: {
    emailRecipient() {
      this.resetData()
    }
  },
  mounted() {
    if (this.contact && this.contact.NoEmailContact) {
      this.$emit('change-to-self', true)
    }
  },
  methods: {
    resetData() {
      if (this.toSelf) {
        this.$emit('change-to-name', this.$store.state.user.user.name)
        this.$emit('change-to', this.$store.state.user.user.email)
        this.$emit('change-salutation', this.$store.state.user.user.name)
      } else if (this.contact) {
        this.$emit('change-to-name', this.contact.ContactName)
        this.$emit('change-salutation', this.contact.Salutation)
        if (this.contact.NoEmailContact) {
          this.$emit('change-to', this.$store.state.user.user.email)
        } else {
          this.$emit('change-to', this.contact.EmailAddress)
        }
      }
    },
    handleContactSearch() {
      this.$emit('search-contact', this.SearchTerm)
    }
  },
  validations: {
    EmailAddress: {
      required,
      validateMultipleEmailsWithNames
    },
    SearchTerm: {
      required
    }
  }
}
</script>

<style scoped>
.custom-margin-bottom-45px {
  margin-bottom: 45px !important;
}
</style>
