<template>
  <form ref="form" method="post" :action="actionUrl" :target="target">
    <input type="hidden" name="token" :value="$store.state.user.documentToken">
    <input type="hidden" name="SOID" :value="soid">
    <input type="hidden" name="Type" :value="type">
    <input type="hidden" name="type" :value="document">
    <input type="hidden" name="format" :value="format">
    <input type="hidden" name="showDays" :value="months">
    <input type="hidden" name="showRotations" :value="rotations">
    <slot name="form-override">
      <label style="font-size: 12px; text-align: center; width: 100%; margin-bottom: 0 !important; line-height: 12px; display: block;">
        <slot>
          Stock Report
        </slot>
      </label>
      <b-button-group class="d-flex" size="sm">
        <b-button block class="wv-btn wv-btn--xs mt-1" :disabled="disabled" @click="handleSubmit('pdf', false)">
          PDF
        </b-button>
        <b-button block class="wv-btn wv-btn--xs mt-1" :disabled="disabled" @click="handleSubmit('xlsx', false)">
          XLSX
        </b-button>
      </b-button-group>
      <label style="font-size: 12px; text-align: center; width: 100%; margin-bottom: 0 !important; line-height: 12px; display: block;" class="mt-1">
        With Rotations
      </label>
      <b-button-group class="d-flex" size="sm">
        <b-button block class="wv-btn wv-btn--xs mt-1" :disabled="disabled" @click="handleSubmit('pdf', true)">
          PDF
        </b-button>
        <b-button block class="wv-btn wv-btn--xs mt-1" :disabled="disabled" @click="handleSubmit('xlsx', true)">
          XLSX
        </b-button>
      </b-button-group>
    </slot>
  </form>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    soid: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      default: 'xlsx'
    },
    target: {
      type: String,
      default: 'self'
    },
    document: {
      type: String,
      default: 'storage'
    },
    showMonths: {
      type: Boolean,
      default: null
    },
    showRotations: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      format: 'pdf',
      months: true,
      rotations: false
    }
  },
  computed: {
    actionUrl() {
      return process.env.apiHost + '/document/reserves'
    }
  },
  mounted() {
    // console.log(this.showMonths)
    if (this.showMonths !== null) {
      this.months = this.showMonths
    }

    if (this.showRotations !== null) {
      this.rotations = this.showRotations
    }
  },
  methods: {
    handleSubmit(format = 'pdf', rotations = false) {
      this.format = format === 'pdf' ? 'pdf' : 'xlsx'
      // this.months = months === true
      this.rotations = rotations === true
      this.submit()
    },
    submit() {
      this.$nextTick(() => {
        this.$refs.form.submit()
      })
    }
  }
}
</script>
