<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: null
    },
    dp: {
      type: Number,
      required: false,
      default: 2
    },
    currency: {
      type: Boolean,
      required: false,
      default: true
    },
    iso: {
      type: String,
      default: process.env.currency_iso
    }
  },
  computed: {
    formattedValue() {
      const formatter = new Intl.NumberFormat(process.env.locale, {
        style: this.currency ? 'currency' : 'decimal',
        currency: this.iso,
        minimumFractionDigits: this.dp
      })
      return formatter.format(this.value) /* $2,500.00 */
    }
  }
}
</script>
