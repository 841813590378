<template>
  <div>
    <purchase-order-export v-if="hasPurchaseOrderEmail" ref="form" class="flex-grow-1" :poid="poid" type="pdf-stream" target="purchase-frame">
      <template slot="form-override">
        <iframe name="purchase-frame" style="background: white;" class="h-100" />
      </template>
    </purchase-order-export>
    <web-purchase-order-export v-if="hasWebPurchaseOrderEmail" ref="form" class="flex-grow-1" :wpoid="wpoid" type="pdf-stream" target="web-purchase-frame">
      <template slot="form-override">
        <iframe name="web-purchase-frame" style="background: white;" class="h-100" />
      </template>
    </web-purchase-order-export>
    <etally-export v-if="hasEtallyEmail" ref="form" class="flex-grow-1" :etcid="etcid" type="pdf-stream" target="etally-frame">
      <template slot="form-override">
        <iframe name="etally-frame" style="background: white;" class="h-100" />
      </template>
    </etally-export>
    <sales-order-export v-if="hasSalesOrderEmail && !isStorageReport" ref="form" class="flex-grow-1" :soid="soid" :is-storage="isStorage" type="pdf-stream" target="sales-frame">
      <template slot="form-override">
        <iframe name="sales-frame" style="background: white;" class="h-100" />
      </template>
    </sales-order-export>
    <sales-order-storage-export
      v-if="hasSalesOrderEmail && isStorageReport"
      ref="form"
      class="flex-grow-1"
      :soid="soid"
      type="pdf-stream"
      target="sales-report-frame"
      :show-months="showMonths"
      :show-rotations="showRotations"
    >
      <template slot="form-override">
        <iframe name="sales-report-frame" style="background: white;" class="h-100" />
      </template>
    </sales-order-storage-export>
    <template v-if="hasWineListEmail">
      <report-request-form ref="form" :value="wineListReportParams" class="flex-grow-1" target="winelist-frame">
        <template slot="form-override">
          <iframe name="winelist-frame" style="background: white;" class="h-100" />
        </template>
      </report-request-form>
    </template>
  </div>
</template>

<script>
import PurchaseOrderExport from '../../PurchaseOrders/PurchaseOrderExport'
import SalesOrderExport from '../../SalesOrders/SalesOrderExport'
import WebPurchaseOrderExport from '../../Web/PurchaseOrder/WebPurchaseOrderExport'
import EtallyExport from '../../ETally/CardExport'
import ReportFormMixin from '~/mixins/modal/wineListExportModal/ReportForm'
import SettingsMixin from '~/mixins/modal/wineListExportModal/Settings'
import SalesOrderStorageExport from '../../SalesOrders/SalesOrderStorageExport'

export default {
  components: {
    PurchaseOrderExport,
    SalesOrderExport,
    WebPurchaseOrderExport,
    EtallyExport,
    SalesOrderStorageExport
  },
  mixins: [ReportFormMixin, SettingsMixin],
  props: {
    attachment: {
      type: Object,
      required: true
    },
    shown: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    wineListFileType() {
      if (this.attachment.Key === 'WineListPDF') {
        return 'pdf-stream'
      }
      if (this.attachment.Key === 'WineListXLS') {
        return 'xlsx'
      }
      if (this.attachment.Key === 'WineListCSV') {
        return 'csv'
      }
      return 'pdf-stream'
    },
    wineListReportParams() {
      return {
        ...this.reportFormParameters,
        Type: this.wineListFileType
      }
    },
    hasPurchaseOrderEmail() {
      return (
        this.$store.state.export.email.emailApiArgs.PurchaseOrderEmail !==
        undefined
      )
    },
    hasEtallyEmail() {
      return (
        this.$store.state.export.email.emailApiArgs.ETallyEmail !== undefined
      )
    },
    hasWebPurchaseOrderEmail() {
      return (
        this.$store.state.export.email.emailApiArgs.WebPurchaseOrderEmail !==
        undefined
      )
    },
    hasSalesOrderEmail() {
      return (
        this.$store.state.export.email.emailApiArgs.SalesOrderEmail !==
        undefined
      )
    },
    hasWineListEmail() {
      return (
        this.$store.state.export.email.emailApiArgs.WineListExport !== undefined
      )
    },
    poid() {
      return this.$store.state.export.email.emailApiArgs.PurchaseOrderEmail.POID
    },
    wpoid() {
      return this.$store.state.export.email.emailApiArgs.WebPurchaseOrderEmail
        .WPOID
    },
    soid() {
      return this.$store.state.export.email.emailApiArgs.SalesOrderEmail.SOID
    },
    etcid() {
      return this.$store.state.export.email.emailApiArgs.ETallyEmail.ETCID
    },
    isStorage() {
      return this.attachment.Key === 'StorageSalesOrder'
    },
    isStorageReport() {
      return (
        this.attachment.Key === 'ReservesStorage' ||
        this.attachment.Key === 'ReservesStorageMonths' ||
        this.attachment.Key === 'ReservesStorageRotations'
      )
    },
    showMonths() {
      if (
        this.attachment.Key === 'ReservesStorageMonths' ||
        this.attachment.Key === 'ReservesStorageRotations'
      ) {
        return true
      } else if (this.attachment.Key === 'ReservesStorage') {
        return false
      }
      return null
    },
    showRotations() {
      if (this.attachment.Key === 'ReservesStorageRotations') {
        return true
      } else if (
        this.attachment.Key === 'ReservesStorage' ||
        this.attachment.Key === 'ReservesStorageMonths'
      ) {
        return false
      }
      return null
    }
  },
  watch: {
    shown(active) {
      if (active) {
        this.$refs.form.submit()
      }
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  min-height: 600px;
}
</style>
