import Entity from './entity'

const sageFields = {
  IsPosted: {
    type: Boolean
  },
  IsPrinted: {
    type: Boolean
  },
  Enabled: {
    type: Boolean
  },
  UpToDate: {
    type: Boolean
  },
  UpdateDate: {
    type: String
  },
  StatusID: {
    type: Number
  },
  StatusText: {
    type: String
  },
  IsFinalised: {
    type: Boolean
  },
  Exists: {
    type: Boolean
  }
}

const xeroFields = {
  InvoiceNumber: {
    type: String
  },
  XeroInvoiceID: {
    type: String
  },
  XeroURL: {
    type: String
  },
  Status: {
    type: String
  },
  PaidStatus: {
    type: Number
  },
  PaidDate: {
    type: String
  },
  UpdatedDate: {
    type: Number
  },
  Enabled: {
    type: Boolean
  },
  IsFinalised: {
    type: Boolean
  },
  IsPosted: {
    type: Boolean
  },
  Connected: {
    type: Boolean
  }
}

const fields = {
  SOID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  WorkflowStatusID: {
    type: Number
  },
  OrderDate: {
    type: String
  },
  PaymentTermsID: {
    type: Number
  },
  PaymentTerms: {
    type: String
  },
  SageID: {
    type: Number
  },
  Carriage: {
    type: Number
  },
  DeliveryInstructionsID: {
    type: Number
  },
  DeliveryInstructions: {
    type: String
  },
  CustomerOrderNumber: {
    type: String
  },
  ExportID: {
    type: Number
  },
  IsExport: {
    type: Boolean
  },
  VATNo: {
    type: String
  },
  DeliveryID: {
    type: Number
  },
  IsContactAsDelivery: {
    type: Boolean
  },
  IsDeliveryNotesComplete: {
    type: Boolean
  },
  ShippingInvoicePath: {
    type: String
  },
  Unallocated: {
    type: Number
  },
  OrderNote: {
    type: String
  },
  DeliveryAddressID: {
    type: Number
  },
  DeliveryName: {
    type: String
  },
  DeliveryAddress1: {
    type: String
  },
  DeliveryAddress2: {
    type: String
  },
  DeliveryAddress3: {
    type: String
  },
  DeliveryAddress4: {
    type: String
  },
  DeliveryPostcode: {
    type: String
  },
  DeliveryCountryCode: {
    type: String
  },
  DeliveryCountryName: {
    type: String
  },
  DeliveryPhone: {
    type: String
  },
  DeliveryForAttentionOf: {
    type: String
  },
  DeliveryFax: {
    type: String
  },
  DeliveryEmail: {
    type: String
  },
  CountryCode: {
    type: String
  },
  UserID: {
    type: Number
  },
  InternalNotes: {
    type: String
  },
  PublicNotes: {
    type: String
  },
  IsLocked: {
    type: Boolean
  },
  SalesOrderLinesCount: {
    type: Number
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedBy: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedByUserName: {
    type: String
  },
  LastPdfPrintDate: {
    type: String
  },
  UseBillingAddress: {
    type: Boolean
  },
  SageCustomerAcctNo: {
    type: String
  },
  Reference: {
    type: String
  },
  Sage: {
    type: Object,
    fields: sageFields
  },
  Xero: {
    type: Object,
    fields: xeroFields
  },
  SOPSID: {
    type: Number
  },
  SODSID: {
    type: Number
  },
  SalesOrderPaidDate: {
    type: String
  },
  SalesOrderShippedDate: {
    type: String
  },
  ShipperAddrID: {
    type: Number
  },
  IBShippingReferencesAsString: {
    type: String
  },
  DPShippingReferencesAsString: {
    type: String
  },
  SaleOrderTypeID: {
    type: Number
  },
  ReservePOID: {
    type: Number
  },
  ReservePOType: {
    type: Number
  },
  StorageSOID: {
    type: Number
  },
  OriginalSOID: {
    type: Number
  }
}

// const sageField = {
//   Sage: {
//     type: Object,
//     fields: {
//       IsFinalised: {
//         type: Boolean
//       }
//     }
//   }
// }

const byCustomerFields = {
  ...Entity.onlyKeys(fields, [
    'SOID',
    'OrderDate',
    'CustomerOrderNumber',
    'InternalNotes',
    'PublicNotes',
    'DeliveryAddressID',
    'Reference'
  ]),
  ContactId: {
    type: Number
  },
  SageCustomerAcctNo: {
    type: String
  },
  WorkflowStatusDescription: {
    type: String
  },
  DeliveryDescription: {
    type: String
  },
  SageUpdatedDate: {
    type: String
  },
  Initials: {
    type: String
  },
  DPLines: {
    type: Number
  },
  IBLines: {
    type: Number
  },
  IncompleteDPLines: {
    type: Number
  },
  IncompleteIBLines: {
    type: Number
  },
  CreateDeliveryNote: {
    type: Boolean
  },
  ShowABV: {
    type: Boolean
  }
}

const SalesOrder = new Entity('SalesOrder', fields)

SalesOrder.createQuery('get', 'salesOrderGet', fields)
SalesOrder.createQuery('getByCustomer', 'salesOrdersGetByCustomer', {
  current_page: {
    type: Number
  },
  last_page: {
    type: Number
  },
  next_page_url: {
    type: String
  },
  per_page: {
    type: Number
  },
  prev_page_url: {
    type: String
  },
  total: {
    type: Number
  },
  salesOrderByCustomerResult: {
    type: Array,
    fields: byCustomerFields
  }
})

SalesOrder.createQuery('getForShipping', 'salesOrderSearchForShipping', {
  current_page: {
    type: Number
  },
  last_page: {
    type: Number
  },
  next_page_url: {
    type: String
  },
  per_page: {
    type: Number
  },
  prev_page_url: {
    type: String
  },
  total: {
    type: Number
  },
  salesOrderForShippingResult: {
    type: Array,
    fields: {
      ...byCustomerFields,
      CompanyName: {
        type: String
      },
      SOPSID: {
        type: Number
      },
      SODSID: {
        type: Number
      },
      SalesOrderPaidDate: {
        type: String
      },
      SalesOrderShippedDate: {
        type: String
      },
      ShipperAddrID: {
        type: Number
      },
      IBShippingReferencesAsString: {
        type: String
      },
      DPShippingReferencesAsString: {
        type: String
      },
      ShipperDescription: {
        type: String
      },
      ShipperCorrelationIdent: {
        type: String
      },
      PublicNotes: {
        type: String
      },
      ReserveOrderValue: {
        type: Number
      },
      // Not supported by backend yet
      // StorageOrderValue: {
      //   type: Number
      // },
      OrderValue: {
        type: Number
      },
      SalesOrderTypeID: {
        type: Number
      },
      XeroAccountNumber: {
        type: String
      },
      XeroURL: {
        type: String
      },
      XeroStatus: {
        type: String
      },
      XeroContactURL: {
        type: String
      }
    }
  }
})

SalesOrder.createQuery('shippingDocumentGet', 'salesOrderShippingDocuments', {
  UUID: {
    type: String
  },
  OriginalFilename: {
    type: String
  },
  Size: {
    type: Number
  }
})

SalesOrder.createMutation('update', 'salesOrderUpdate', {
  ...Entity.onlyKeys(fields, [
    'SOID',
    'AuditAmendedDate',
    'OrderDate',
    'ExportID',
    'CountryCode',
    'WorkflowStatusID',
    'PaymentTermsID',
    'PublicNotes',
    'InternalNotes',
    'DeliveryInstructionsID',
    'Carriage',
    'ContactID',
    'CustomerOrderNumber',
    'DeliveryEmail',
    'UseBillingAddress',
    'DeliveryAddressID',
    'Reference',
    'SOPSID',
    'SODSID',
    'SalesOrderPaidDate',
    'SalesOrderShippedDate',
    'ShipperAddrID'
  ]),
  DeliveryPhone: {
    type: String
  },
  CustomerAddressPhone: {
    type: String
  },
  UpdatedShippingLines: {
    type: Array,
    fields: {
      SOLID: {
        type: Number
      },
      Shipped: {
        type: Boolean
      }
    }
  },
  ShippingReferences: {
    type: Array,
    fields: {
      DutyStatusID: {
        type: Number
      },
      Reference: {
        type: String
      }
    }
  },
  FileUUIDs: {
    type: Array
  }
})

SalesOrder.createMutation('create', 'salesOrderAdd', {
  ...Entity.onlyKeys(fields, [
    'SOID',
    'AuditAmendedDate',
    'OrderDate',
    'ExportID',
    'CountryCode',
    'WorkflowStatusID',
    'PaymentTermsID',
    'PublicNotes',
    'InternalNotes',
    'DeliveryInstructionsID',
    'Carriage',
    'ContactID',
    'CustomerOrderNumber',
    'DeliveryEmail',
    'UseBillingAddress',
    'DeliveryAddressID',
    'Reference',
    'SOPSID',
    'SalesOrderPaidDate',
    'SalesOrderShippedDate',
    'ShipperAddrID'
  ]),
  DeliveryPhone: {
    type: String
  },
  CustomerAddressPhone: {
    type: String
  }
})

SalesOrder.createMutation(
  'createWithTransaction',
  'salesOrderTransactionCreate',
  {
    WineCardID: {
      type: Number
    },
    FormatID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    Price: {
      type: Number
    },
    AsDutyPaid: {
      type: Number
    }
  }
)

SalesOrder.createMutation(
  'updateSage',
  'salesOrderUpdateSage',
  Entity.onlyKeys(fields, ['SOID']),
  fields
)

SalesOrder.createMutation(
  'delete',
  'salesOrderDelete',
  Entity.onlyKeys(fields, ['SOID', 'AuditAmendedDate']),
  []
)

SalesOrder.createMutation(
  'reserveCreateFromSalesOrder',
  'reserveCreateFromSalesOrder',
  Entity.onlyKeys(fields, ['SOID']),
  {
    POID: {
      type: Number
    }
  }
)

SalesOrder.createMutation(
  'deleteDocument',
  'salesOrderDocumentDelete',
  {
    SOID: {
      type: Number
    },
    UUID: {
      type: String
    }
  },
  []
)

SalesOrder.createMutation(
  'shippingUpdateBatch',
  'salesOrderShippingBatchUpdate',
  {
    SOID: {
      type: Array
    },
    SalesOrderShippedDate: {
      type: String
    },
    ShippingReferences: {
      type: Array,
      fields: {
        DutyStatusID: {
          type: Number
        },
        Reference: {
          type: String
        }
      }
    },
    FileUUIDs: {
      type: Array
    },
    ShipperAddrID: {
      type: Number
    }
  },
  {
    SalesOrders: {
      type: Array,
      fields: {
        ...byCustomerFields,
        CompanyName: {
          type: String
        },
        SOPSID: {
          type: Number
        },
        SODSID: {
          type: Number
        },
        SalesOrderPaidDate: {
          type: String
        },
        SalesOrderShippedDate: {
          type: String
        },
        ShipperAddrID: {
          type: Number
        },
        IBShippingReferencesAsString: {
          type: String
        },
        DPShippingReferencesAsString: {
          type: String
        },
        ShipperDescription: {
          type: String
        },
        ShipperCorrelationIdent: {
          type: String
        }
      }
    },
    ShippingInstructions: {
      type: Array
    }
  }
)

SalesOrder.createMutation(
  'shippingUpdatePaidBatch',
  'salesOrderPaidBatchUpdate',
  {
    SOID: {
      type: Array
    },
    SalesOrderPaidDate: {
      type: String
    }
  },
  {
    SalesOrders: {
      type: Array,
      fields: {
        ...byCustomerFields,
        CompanyName: {
          type: String
        },
        SOPSID: {
          type: Number
        },
        SODSID: {
          type: Number
        },
        SalesOrderPaidDate: {
          type: String
        },
        SalesOrderShippedDate: {
          type: String
        },
        ShipperAddrID: {
          type: Number
        },
        IBShippingReferencesAsString: {
          type: String
        },
        DPShippingReferencesAsString: {
          type: String
        },
        ShipperDescription: {
          type: String
        },
        ShipperCorrelationIdent: {
          type: String
        }
      }
    }
  }
)

export default SalesOrder
